.cveMainDetails {
    width: 81%;
    justify-content: center;
    align-items: center;
    row-gap: 45px;
    margin: 120px auto 0px;
    font-family: 'Satoshi', sans-serif;
}

.cve-details {
    background-color: rgba(34, 34, 34, 1);
    padding: 24px 30px;
    border-radius: 15px;
    width:100%;
}

.tabsWrapper {
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid rgba(17, 17, 17, 1);
}

.outerBgBox {
    background: rgba(34, 34, 34, 1);
    border-radius: 15px;
}

/* .innerBgBox {
    margin: var(--chakra-space-4);
    outline: 2px solid transparent;
    outline-offset: 2px;
    background: #00000066;
    padding: 12px;
} */

.chakra-tabs__tab-panel > p, .chakra-tabs__tablist > button {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.06em;
    font-family: 'Satoshi', sans-serif;
}

.cve-details .chakra-tabs__tablist {
    border: unset
}

.cve-details [aria-selected=true] {
    border-bottom: 2px solid;
    border-color: rgba(246, 0, 0, 1)!important;
}

.cvePage-pies-wrapper {
    width: 45%;
}

@media (max-width: 1024px) {
    .tabsWrapper {
        & .chakra-tabs__tablist {
            display: flex;
            flex-direction: column;
        }
    }
    .cveMainDetails .cveId {
        font-size: 36px;
    }
}