.blog-wrapper {
    width: 81%;
    margin: 0 auto;
    align-items: center;
    margin-top: 30px;
    height: fit-content;
}

.blog-posts {
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 33px;
}

.blog-posts .blog-post {
    flex-direction: column;
    width: 30%;
    height: 540px;
    /* border: 1px solid red; */
    background: #222222;
    border-radius: 16px;
    overflow: hidden;
}

.post-image-wrapper {
    width: 100%;
    height: 39%;
    min-height: 39%;
    max-height: 39%;
}

.post-image-wrapper img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.post-content {
    display: flex;
    flex-direction: column;
    padding: 15px 18px;
    height: -webkit-fill-available;
    row-gap: 15px;
    letter-spacing: .12em;
}

.post-title {
    font-size: 24px;
    font-weight: 700;
}

.post-content-preview {
    font-size: 15px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rdw-dropdown-selectedtext,
.rdw-fontsize-option,
.rdw-image-modal {
    color: #000000;
}

.rdw-image-modal-size-input,
.rdw-image-modal-url-input {
    background: #dddddd;
    padding: 3px 9px;
}

@media (max-width: 768px) {
    .blog-posts .blog-post {
        width: 100%;
    }

    .post-title {
        font-size: 18px;
    }
}