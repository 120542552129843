.content-wrapper .credentials-search-switch {
    margin-bottom: 15px;
}

.content-wrapper .credentials-search-switch a {
    color: var(--chakra-colors-whiteAlpha-900);
    padding: 9px 15px;
}


.content-wrapper .credentials-search-switch a.active {
    border-bottom: 1px solid #E41E20;
    background: linear-gradient(0deg, rgba(228,30,32,0.45) 0%, rgba(228,30,32,0.45) 6%, rgba(0,0,0,0) 21%, rgba(0,0,0,0) 100%);
}