.nav-wrapper {
    width: 81%;
    padding: 12px 0;
    align-items: center;
}

.trends {
    column-gap: 18px;
}

.nav-heading {
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.nav-links {
    display: flex;
    column-gap: 24px;
}

@media (max-width: 768px) {
    .nav-wrapper {
        padding: 12px 0;
        align-items: center;
        flex-direction: column;

        & .trends {
            display: none;
        }

        & .buttons {
            margin-top: 9px;
            margin-left: revert;
        }
    }

    .nav-links {
        display: flex;
        justify-items: center;
        align-items: center;
        text-align: center;
        row-gap: 18px;
        column-gap: 14px;
    }
}