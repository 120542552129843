.cvePage-pies-wrapper > div {
    width: 45%;
}

.ipDetails {
    margin-top: 12px;
    row-gap: 12px;
}

.ipDetails h3 {
    font-weight: 400;
    color: rgba(167, 167, 167, 1);
}

.customModal .sameAsnIpWrapper {
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-align: left;
    border-radius: 3px;
    padding: 3px 12px;
    column-gap: 12px;
}
.customModal .sameAsnIpWrapper:nth-child(odd) {
    background: rgba(17, 17, 17, 1);
}
.customModal .sameAsnIpWrapper:nth-child(even) {
    background: transparent;
}

@media (max-width: 425px) {
    .cvePage-pies-wrapper > div {
        width: 100%!important;
    }

    .infoComponentWrapper .cveTotalAttempts {
        flex-direction: column;
    }

    :has(>.custom-accordion-title) > *:not(.custom-accordion-title) {
        display: none;
    }

    .cvePage-pies-wrapper {
        flex-direction: column;
        row-gap: 30px;
    }
    
    .custom-accordion-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 192px;
    }

    .pagination-wrapper > div > button {
        display: none;
    }

    .searchQueryTitle {
        font-size: 30px!important;
    }
}