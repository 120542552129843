.dashboardWrapper {
    font-family: 'Satoshi', sans-serif;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 39px;
}

.chartWrapper {
    position: relative;
    background: rgba(34, 34, 34, 1);
    width: 100%;
    border-radius: 15px;
    height: 100%;
}

.chartDisplay {
    margin: var(--chakra-space-4);
    outline: 2px solid transparent;
    outline-offset: 2px;
    /* background: #00000066; */
    padding: 12px;
    width: auto !important;
}

.recharts-wrapper>svg {
    overflow: visible;
}

.card-loading-indicator-text {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.21em;
    color: rgba(255, 255, 255, 0.6);
}

.card-loading-indicator-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
}

@media (max-width: 768px) {
    .dashboardWrapper {
        flex-direction: column;
        align-items: center;

        &.chartWrapper {
            width: 100%;
        }
    }
}