:has(>.content-wrapper) {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 60px 0px;
}

input {
    border-radius: 4px !important;
}

.content-wrapper {
    width: 51%;
    display: flex;
    flex-direction: column;
    row-gap: 45px;
}

.suggestions-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 3px;
}

.suggestion-wrapper:hover {
    background: rgba(255, 255, 255, 0.09);
    transition: .3s ease-out;
}

.suggestion-wrapper {
    display: flex;
    justify-content: space-between;
    width: 21%;
    padding: 7px 15px;
    margin: 6px 0px;
    border: 1px solid #272727;
    color: #A7A7A7;
    background: rgba(255, 255, 255, 0.03);
    cursor: pointer;
    line-height: 0em;
    align-items: center;
}

.suggestion-wrapper.active {
    background: #e41e214d;
    border: 1px solid #e41e21;
}

.suggestion-wrapper p {
    font-size: 14px;
}

.input-string-indicator {
    width: fit-content!important;
}

.home-footer {
    width: 81%;
    position: absolute;
    bottom: 70px;
    padding-top: 39px;
    border-top: 1px solid #272727;
}

#root:has(.home-footer) {
    height: 100%;
    background: radial-gradient(145% 65% at 50% 30%, rgba(227, 30, 32, 0) 70.5%, rgba(227, 30, 32, 0.45) 110%);
}

@media (max-width: 768px) {
    .suggestions-wrapper {
        flex-direction: column;

        & .suggestion-wrapper {
            width: 100%;
        }
    }

    .home-footer {
        flex-direction: column;
        row-gap: 21px;
    }
}

@media (max-width: 425px) {
    .input-string-indicator {
        position: relative!important;
        width: 100%!important;
    }

    .searchInputWrapper1 {
        flex-direction: column;
    }

    .button-submit1 {
        padding: 12px;
        width: 100%;
    }

    .searchInputWrapper1 > input {
        padding: 6px 12px;
    }
}